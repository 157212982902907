// Icons
import HomeIcon from '@material-ui/icons/Home';

// Pages
import {
  Home,
  Viewer,
} from './pages';

export default {
  items: [
    {
      path: '/home',
      name: 'ホーム',
      key: 'home',
      type: 'link',
      icon: HomeIcon,
      component: Home,
      sideBar: true,
    },
    {
      path: '/viewer/:pid',
      name: 'ビューワー',
      key: 'view',
      type: 'link',
      icon: HomeIcon,
      component: Viewer,
      sideBar: false,
    },
  ]
};
