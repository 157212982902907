import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { Link } from "react-router-dom";
import { Auth } from "aws-amplify";
import SignInStyles from "./SignInStyles";
import { connect } from "react-redux";
import { setIdToken, setAccessKey, setUserName, setUserEmail} from "../../actions";

export class SignIn extends Component {
  constructor(props) {
    super(props);

    this.state = {
      username: "",
      password: "",
      errorMessage: "",
      showNewPassword: false,
      newPassword: "",
      confirmPassword: "",
      newUser: "",
    };
  }

  componentWillMount() {
    ValidatorForm.addValidationRule("isPasswordMatch", (value) => {
      if (value !== this.state.newPassword) {
        return false;
      }
      return true;
    });
    ValidatorForm.addValidationRule("checkPasswordLength", (value) => {
      if (value !== "" && value.length < 6) {
        return false;
      }
      return true;
    });
    ValidatorForm.addValidationRule("checkPasswordLowerCase", (value) => {
      if (value !== "" && value.search(/[a-z]/) < 0) {
        return false;
      }
      return true;
    });
    ValidatorForm.addValidationRule("checkPasswordUpperCase", (value) => {
      if (value !== "" && value.search(/[A-Z]/) < 0) {
        return false;
      }
      return true;
    });
    ValidatorForm.addValidationRule("checkPasswordSpecialChar", (value) => {
      if (
        value !== "" &&
        value.search(/[-!@$%^&*()_+|~=`{}[\]:";'<>?,./]/) < 0
      ) {
        return false;
      }
      return true;
    });
  }

  validateSignInForm() {
    return this.state.username.length > 0 && this.state.password.length > 0;
  }

  validateNewPwForm() {
    return (
      this.state.newPassword.length > 6 &&
      this.state.newPassword === this.state.confirmPassword
    );
  }

  handleChange = (event) => {
    this.setState({ [event.target.id]: event.target.value.trim() });
    this.setState({ errorMessage: "" });
  };

  showNewPasswordSet = () => {
    this.setState({ showNewPassword: true });
  };

  setNewPassword = async (event) => {
    event.preventDefault();
    try {
      Auth.completeNewPassword(
        this.state.newUser,
        this.state.newPassword,
        this.state.newUser.challengeParam.requiredAttributes
      ).then((user) => {
        this.props.userHasAuthenticated(true);
      });
    } catch (e) {
      console.log(e.message);
    }
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    try {
      await Auth.signIn(this.state.username, this.state.password).then(
        (user) => {
          if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
            this.showNewPasswordSet();
            this.setState({ newUser: user });
          } else {
            // localStorage.setItem(
            //   "client_id",
            //   user.attributes["custom:client_id"]
            // );
            // localStorage.setItem(
            //   "client_name",
            //   user.attributes["custom:client_name"]
            // );
            // localStorage.setItem(
            //   "access_key",
            //   user.attributes["custom:access_key"]
            // );
            localStorage.setItem("username", user.username);
            localStorage.setItem("email", user.attributes.email);
            localStorage.setItem(
              "idToken",
              user.signInUserSession.idToken.jwtToken
            );
            this.props.setIdToken(user.signInUserSession.idToken.jwtToken);
            this.props.setAccessKey(user.attributes["custom:access_key"]);
            this.props.setUserName(user.username);
            this.props.setUserEmail(user.attributes["email"]);
            this.props.userHasAuthenticated(true);
          }
        }
      );
    } catch (e) {
      const errorMsg = e.message === "Incorrect username or password."
        ? "ユーザー名もしくはパスワードに誤りがあります。" : e.message;
      this.setState({ errorMessage: errorMsg });
    }
  };

  renderNewPasswordForm() {
    const { classes } = this.props;
    return (
      <Card>
        <CardContent>
          <ValidatorForm
            ref="form"
            onSubmit={this.setNewPassword}
            onError={(errors) => console.log(errors)}
          >
            <Typography variant="h6">パスワードの設定</Typography>
            <div className={classes.formMessage}>
              一時的なパスワードを更新してください。パスワードは少なくとも6文字以上、大文字と小文字が混在し、特殊文字が含まれている必要があります。
            </div>
            <TextValidator
              id="newPassword"
              name="newPassword"
              label="新しいパスワード"
              type="password"
              fullWidth={true}
              onChange={this.handleChange}
              value={this.state.newPassword || ""}
              validators={[
                "checkPasswordLength",
                "checkPasswordLowerCase",
                "checkPasswordUpperCase",
                "checkPasswordSpecialChar",
              ]}
              errorMessages={[
                "パスワードには最低6文字の英数字が必要です",
                "パスワードには最低1文字の英字の小文字が必要です",
                "パスワードには最低1文字の英字の大文字が必要です",
                "パスワードには最低1文字の特殊文字が必要です",
              ]}
              className={classes.pwTextField}
            />
            <TextValidator
              id="confirmPassword"
              name="confirmPassword"
              label="パスワードの再度入力"
              type="password"
              fullWidth={true}
              onChange={this.handleChange}
              value={this.state.confirmPassword || ""}
              validators={["isPasswordMatch"]}
              errorMessages={["パスワードが一致していません"]}
              className={classes.pwTextField}
            />
            <Button
              className={classes.updateButton}
              variant="raised"
              fullWidth
              type="submit"
              disabled={!this.validateNewPwForm()}
            >
              更新する
            </Button>
          </ValidatorForm>
        </CardContent>
      </Card>
    );
  }

  renderSignInForm() {
    const { classes } = this.props;
    return (
      <Card>
        <CardContent>
          <form onSubmit={this.handleSubmit}>
            <TextField
              id="username"
              label="Eメール"
              className={classes.textField}
              fullWidth
              margin="normal"
              onChange={this.handleChange}
              variant="filled"
            />
            <TextField
              id="password"
              label="パスワード"
              className={classes.textField}
              type="password"
              fullWidth
              margin="normal"
              onChange={this.handleChange}
              variant="filled"
            />
            <Button
              className={classes.signInButton}
              variant="contained"
              fullWidth
              type="submit"
              disabled={!this.validateSignInForm()}
            >
              ログイン
            </Button>
            <div className={classes.errorMessageDiv}>
              <Typography className={classes.errorMessage}>
                {this.state.errorMessage}
              </Typography>
            </div>
            <div className="pt-1 text-md-center">
              <Link to="/signin/reset">
                <Button fullWidth>パスワードをお忘れですか?</Button>
              </Link>
              &nbsp;&nbsp;&nbsp;&nbsp;
            </div>
          </form>
        </CardContent>
      </Card>
    );
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classNames(classes.session, classes.background)}>
        <div className={classes.content}>
          <div className={classes.wrapper}>
            <img
              className={classes.logoImg}
              src={`/static/images/logo-main.png`}
            />
            {this.state.showNewPassword
              ? this.renderNewPasswordForm()
              : this.renderSignInForm()}
          </div>
        </div>
      </div>
    );
  }
}

SignIn.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  setIdToken: (param) => dispatch(setIdToken(param)),
  setAccessKey: (param) => dispatch(setAccessKey(param)),
  setUserName: (param) => dispatch(setUserName(param)),
  setUserEmail: (param) => dispatch(setUserEmail(param)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(SignInStyles, { withTheme: true })(SignIn));
