const SidebarItemStyles = theme => ({
    badge: {
      width: '20px',
      height: '20px',
      display: 'flex',
      zIndex: 1,
      flexWrap: 'wrap',
      fontSize: '0.75rem',
      alignItems: 'center',
      borderRadius: '50%',
      alignContent: 'center',
      flexDirection: 'row',
      justifyContent: 'center'
    },
    menuLink: {
      position: 'relative',
      display: 'block',
      textDecoration: 'none',
    },
    menuItem: {
      fontSize: '0.94rem',
      paddingLeft: 0,
      paddingRight: 0,
      color: "#333",
      fontFamily: 'Varta,Arial,sans-serif',
    },
    menuIcon: {
      marginLeft: theme.spacing(2),
      paddingRight: theme.spacing(1),
      marginRight: theme.spacing(2)
    },
    menuIconFa: {
      marginLeft: theme.spacing(2.5),
      paddingRight: theme.spacing(1),
      marginRight: theme.spacing(2)
    },
    menuSubItem: {
      paddingLeft: '55px',
      paddingRight: '55px'
    },
    profileMenuItem: {
      paddingLeft: '70px',
      paddingRight: '55px'
    },
    menuCollapsed: {
      backgroundColor: theme.palette.action.hover,
    },
    menuActive: {
      backgroundColor: theme.palette.action.hover,
    },
    menuClosed: {
      backgroundColor: 'transparent'
    },
    caret: {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
    primary: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
    secondary: {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.secondary.contrastText,
    },
    error: {
      backgroundColor: theme.palette.error.main,
      color: theme.palette.error.contrastText,
    },
    subMenuLabel: {
      marginLeft:theme.spacing(4),
    },
    title: {
      fontSize: 16,
      color: "#333",
    }
});

export default SidebarItemStyles;
