import React, { Fragment, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import { connect, useDispatch, useSelector } from "react-redux";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import MenuIcon from "@material-ui/icons/Menu";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import AccountCircle from "@material-ui/icons/AccountCircle";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import MoreIcon from "@material-ui/icons/MoreVert";
import CloseIcon from '@material-ui/icons/Close';
import LinearProgress from "@material-ui/core/LinearProgress";
import { loadCSS } from "fg-loadcss/src/loadCSS";
import * as H from "../../utils/helper";

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: theme.spacing(0.2),
    marginRight: theme.spacing(1),
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
      marginRight: theme.spacing(2),
    },
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  appBar: {
    boxShadow: "none",
    position: "relative",
    zIndex: theme.zIndex.drawer + 100,
    [theme.breakpoints.down("sm")]: {
      position: "fixed",
    },
    backgroundColor: "#fff",
    borderBottomWidth: "1px",
    borderBottom: "solid #d4d4d4",
  },
  toolBar: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(2),
  },
  logo: {
    marginTop: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      maxWidth: "180px",
    },
  },
  a: {
    marginRight: theme.spacing(2),
    fontSize: 15,
    color: "#000",
  },
}));

const Header = ({ logo, logoAltText, toggleDrawer, handleLogout, currentPage, pageTopLoader, totalAssetStats }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

  useEffect(() => {
    loadCSS(
      "https://use.fontawesome.com/releases/v5.12.0/css/all.css",
      document.querySelector("#font-awesome-css")
    );
  }, []);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleLogout}>
        <ListItemIcon>
          <ExitToAppIcon />
        </ListItemIcon>
        <ListItemText primary="ログアウト" />
      </MenuItem>
    </Menu>
  );

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem onClick={handleMobileMenuClose}>
        <ListItemIcon>
          <ExitToAppIcon />
        </ListItemIcon>
        <ListItemText primary="ログアウト" />
      </MenuItem>
    </Menu>
  );

  const handleClose = () => {
    window.close();
  };

  return (
    <div className={classes.grow}>
      <AppBar position="static" className={classes.appBar}>
        <Toolbar className={classes.toolBar}>
          <IconButton
            className={classes.menuButton}
            color="inherit"
            aria-label="open drawer"
            onClick={toggleDrawer}
          >
            {currentPage === "/home" &&
              <MenuIcon />
            }
            {currentPage.indexOf("/viewer") !== -1 &&
              <CloseIcon onClick={handleClose}  />
            }
          </IconButton>
          <Grid
            container
            spacing={1}
            alignItems="center"
          >
            <Grid item>
              <span className={classes.a}>
                <a href="/home">
                  <img src={logo} alt="logo" className={classes.logo} />
                </a>
              </span>
            </Grid>

            <Grid item xs>
              {/* <Grid
                container
                justifyContent="flex-start"
                alignItems="flex-start"
              >
                {currentPage === "/home" &&
                <Grid item>
                  <span className={classes.a}>
                    撮影物総点数： {" "}{H.FormatNumber.formatCommaThousand(totalAssetStats.denchu)}
                  </span>
                  <span className={classes.a}>
                    ( 電柱 {" "}{H.FormatNumber.formatCommaThousand(totalAssetStats.denchu)}
                  </span>
                  <span className={classes.a}>
                    HH {" "}{H.FormatNumber.formatCommaThousand(totalAssetStats.hh)}
                  </span>
                  <span className={classes.a}>
                    MH: {" "}{H.FormatNumber.formatCommaThousand(totalAssetStats.mh)} )
                  </span>
                </Grid>
                }
              </Grid> */}
            </Grid>

            <Grid item>
              <div className={classes.sectionDesktop}>
                <IconButton
                  edge="end"
                  aria-label="account of current user"
                  aria-controls="primary-search-account-menu"
                  aria-haspopup="true"
                  onClick={handleProfileMenuOpen}
                  color="inherit"
                >
                  <AccountCircle />
                </IconButton>
              </div>
              <div className={classes.sectionMobile}>
                <IconButton
                  aria-label="show more"
                  aria-controls="primary-search-account-menu-mobile"
                  aria-haspopup="true"
                  onClick={handleMobileMenuOpen}
                  color="inherit"
                >
                  <MoreIcon />
                </IconButton>
              </div>
            </Grid>
          </Grid>
        </Toolbar>
        {pageTopLoader ? <LinearProgress /> : null}
      </AppBar>
      {renderMobileMenu}
      {renderMenu}
    </div>
  );
};

Header.prototypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  currentPage: state.app.currentPage,
  pageTopLoader: state.app.pageTopLoader,
  totalAssetStats: state.home.totalAssetStats,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(null, { withTheme: true })(Header));
