const APP = {
  SET_CURRENT_PAGE: "SET_CURRENT_PAGE",
  SET_PAGE_TOP_LOADER: "SET_PAGE_TOP_LOADER",
  SET_JWTOKEN: "SET_JWTOKEN",
  SET_ID_TOKEN: "SET_ID_TOKEN",
  SET_ACCESS_KEY: "SET_ACCESS_KEY",
  SET_USERNAME: "SET_USERNAME",
  SET_USER_EMAIL: "SET_USER_EMAIL",
  SET_DATE_RANGE: "SET_DATE_RANGE",
  SET_MIN_MAX_DATE_RANGE: "SET_MIN_MAX_DATE_RANGE",
  SET_PERIOD_TYPE: "SET_PERIOD_TYPE",
  GET_SEASON_DATA: "GET_SEASON_DATA",
  SET_SEASON_DATA: "SET_SEASON_DATA",
  SET_SELECTED_SEASON: "SET_SELECTED_SEASON",
  SET_FILTER_DRAWER_STATE: "SET_FILTER_DRAWER_STATE",
  SET_SELECTED_TAG: "SET_SELECTED_TAG",
  GET_MISSION_DATA: "GET_MISSION_DATA",
  SET_MISSION_DATA: "SET_MISSION_DATA",
  SET_SELECTED_MISSION: "SET_SELECTED_MISSION",
  SET_TAG_SAVING_LOADER: "SET_TAG_SAVING_LOADER",
  GET_SAVED_SELECTED_TAG_DATA_BY_FILENAME: "GET_SAVED_SELECTED_TAG_DATA_BY_FILENAME",
  GET_SAVED_SELECTED_TAG_DATA_BY_EMAIL: "GET_SAVED_SELECTED_TAG_DATA_BY_EMAIL",
  SET_SAVED_SELECTED_TAG_DATA: "SET_SAVED_SELECTED_TAG_DATA",
  SET_DRAWER_TAG_TIP: "SET_DRAWER_TAG_TIP",
  SET_COMMENT_SAVING_LOADER: "SET_COMMENT_SAVING_LOADER",
  SET_ASSET_COMMENT: "SET_ASSET_COMMENT",
  SET_SAVED_COMMENT_DATA: "SET_SAVED_COMMENT_DATA",
  GET_SAVED_COMMENT_DATA_BY_FILENAME: "GET_SAVED_COMMENT_DATA_BY_FILENAME",
};

const HOME = {
  GET_TOTAL_STATS_DATA: "GET_TOTAL_STATS_DATA",
  SET_TOTAL_STATS_DATA: "SET_TOTAL_STATS_DATA",
  GET_HOME_IMAGE_DATA: "GET_HOME_IMAGE_DATA",
  SET_HOME_IMAGE_DATA: "SET_HOME_IMAGE_DATA",
  SET_TOTAL_IMAGE_COUNT: "SET_TOTAL_IMAGE_COUNT",
  SET_CURRENT_PAGE_NUMBER: "SET_CURRENT_PAGE_NUMBER",
  SET_FILTERED_TOTAL: "SET_FILTERED_TOTAL",
  SET_FILTER: "SET_FILTER",
};

const VIEWER = {
  GET_IMAGE_DATA_BY_FILENAME: "GET_IMAGE_DATA_BY_FILENAME",
  SET_IMAGE_DATA_BY_FILENAME: "SET_IMAGE_DATA_BY_FILENAME",
  SET_VIEWER_IMAGE_DATA: "SET_VIEWER_IMAGE_DATA",
};

const ENDPOINT = {
  GET_DATA_API: "/api/data",
  GET_DATA_KPIDASH_API: "/api/data-kpidash",
  GET_DATA_SXI_PHOTO: "/photo",
  GET_DATA_SXI_PHOTO_BY_FILE: "/photo/filename",
};

export { APP, HOME, VIEWER, ENDPOINT };
