import React from "react";
import ReactDOM from "react-dom";
import Amplify from "aws-amplify";
import { BrowserRouter } from "react-router-dom";
import AppProvider from "./components/AppProvider";
import { LocalizationProvider } from '@material-ui/pickers';
import MomentUtils from '@material-ui/pickers/adapter/moment';
import moment, { Moment } from "moment";
import "./index.css";
import App from "./App";
import TagManager from 'react-gtm-module';

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GTM_ID // ここで環境変数を参照
};

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: process.env.REACT_APP_COGNITO_REGION,
    userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
    //identityPoolId: process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_COGNITO_APP_CLIENT_ID,
  },
  Storage: {
    region: process.env.REACT_APP_COGNITO_REGION,
    bucket: process.env.REACT_APP_S3_BUCKET,
    level: "protected",
    identityPoolId: process.env.REACT_APP_S3_IDENTITY_POOL_ID,
  },
});

TagManager.initialize(tagManagerArgs);

ReactDOM.render(
  <AppProvider>
    <BrowserRouter>
      <LocalizationProvider dateLibInstance={moment} dateAdapter={MomentUtils} locale={''}>
        <App />
      </LocalizationProvider>
    </BrowserRouter>
  </AppProvider>,
  document.getElementById("root")
);
