import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import WorkspaceStyles from "./WorkspaceStyles";

const Workspace = (props) => {
  const { classes, children, opened, currentLocation } = props;

  return (
    <main
      id="export-container"
      className={classNames(classes.content, classes[`content-left`], {
        [classes.contentShift]: opened,
        [classes[`contentShift-left`]]: opened,
      })}
    >
      {children}
    </main>
  );
};

Workspace.prototypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  opened: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  //currentPage: state.page.currentPage,
});

export default connect(
  mapStateToProps,
  null
)(withStyles(WorkspaceStyles, { withTheme: true })(Workspace));
