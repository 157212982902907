import React from "react";
import { Route, Switch, Redirect } from"react-router-dom";
import Dashboard from './containers/Dashboard';
import { SignIn, ResetPassword } from './pages';
import AuthenticatedRoute from "./components/Auth/AuthenticatedRoute";
import UnauthenticatedRoute from "./components/Auth/UnauthenticatedRoute";

export default ({ childProps }) =>
  <Switch>
    <Route exact path="/" render={() => (<Redirect to="/home" />)} />
    <UnauthenticatedRoute path="/signin" exact component={SignIn} props={childProps} />
    <UnauthenticatedRoute path="/signin/reset" exact component={ResetPassword} props={childProps} />
    <AuthenticatedRoute path="/home" exact component={Dashboard} props={childProps} />
    <AuthenticatedRoute path="/viewer/:pid" exact component={Dashboard} props={childProps} />
  </Switch>;