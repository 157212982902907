import { VIEWER } from "../constants";

const initialState = {
  imageDataByFilename: [],
  viewerImageData: [],
};

export default function viewerReducer(state = initialState, action) {
  switch (action.type) {
    case VIEWER.SET_IMAGE_DATA_BY_FILENAME:
      return {
        ...state,
        imageDataByFilename: action.payload,
      };
    case VIEWER.SET_VIEWER_IMAGE_DATA:
      return {
        ...state,
        viewerImageData: action.payload,
      };
    default:
      return state;
  }
}
