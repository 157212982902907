import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { NavLink } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import { withTranslation } from "react-i18next";
import { compose } from "recompose";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import SidebarItemStyles from "./SidebarItemStyles";

class SidebarItem extends Component {
  componentDidMount() {
    if (
      !this.props.currentPath ||
      this.props.activeRoute === this.props.index ||
      this.props.route.path === "/"
    )
      return;
    this.props.toggleMenu(this.props.index);
  }

  getIcon(route, classes) {
    if (typeof route.icon === "object") {
      return <route.icon className={classes.menuIcon} />;
    } else if (route.icon === null) {
      return <route.icon />;
    }
  }

  render() {
    const {
      classes,
      route,
      index,
      activeRoute,
      toggleMenu,
      isProfileMenu,
      disableMenu,
      opened,
      toggleDrawer,
    } = this.props;

    const badge = (badge) => {
      if (!badge) return;
      const badgeClassName = classNames(classes.badge, {
        [classes[`${badge.type}`]]: badge.type !== "default",
      });
      return (
        <Typography
          className={classNames(classes.badge, badgeClassName)}
          component="div"
        >
          {badge.value}
        </Typography>
      );
    };

    return (
      <NavLink
        to={disableMenu ? "#" : route.path}
        exact
        className={classes.menuLink}
        activeClassName={classes.menuActive}
        key={index}
      >
        {isProfileMenu ? (
          <ListItem
            className={classes.profileMenuItem}
            button
            onClick={() => toggleMenu(index)}
          >
            <Typography variant="body2" className="flexSpacer">
              {route.name}
            </Typography>
            {badge(route.badge)}
          </ListItem>
        ) : !opened ? (
          <ListItem
            className={classes.menuItem}
            button
            onClick={() => toggleMenu(index)}
            disabled={disableMenu}
          >
            <Tooltip title={route.name} placement="right">
              <ListItemIcon>{this.getIcon(route, classes)}</ListItemIcon>
            </Tooltip>
            <Typography className="flexSpacer">{route.name}</Typography>
          </ListItem>
        ) : (
          <ListItem
            className={classes.menuItem}
            button
            onClick={() => toggleMenu(index)}
            disabled={disableMenu}
          >
            <ListItemIcon>{this.getIcon(route, classes)}</ListItemIcon>
            <div>{route.name}</div>
          </ListItem>
        )}
      </NavLink>
    );
  }
}

SidebarItem.prototypes = {
  classes: PropTypes.object.isRequired,
  route: PropTypes.object,
  index: PropTypes.number,
  activeRoute: PropTypes.number,
  toggleMenu: PropTypes.func,
};

export default compose(
  withTranslation("translation"),
  withStyles(SidebarItemStyles)
)(SidebarItem);
