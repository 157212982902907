import React from "react";
import PropTypes from "prop-types";
import { withStyles, makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}));

const DashboardWrapper = (props) => {
  const classes = useStyles();
  const { children, padding } = props;

  return <div className={padding ? classes.root : null}>{children}</div>;
};

DashboardWrapper.prototypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  padding: PropTypes.bool,
};

DashboardWrapper.defaultProps = {
  padding: true,
};

export default withStyles(null)(DashboardWrapper);
