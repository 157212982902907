import { Auth } from "aws-amplify";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
const API_BASE_URL_DATA = process.env.REACT_APP_API_BASE_URL_DATA;
const API_KEY_PICTREE_DATA = process.env.REACT_APP_API_KEY_PICTREE_DATA;

export const getJwtToken = async () => {
  const currentSession = await Auth.currentSession();
  const response = await fetch(
    "https://cognito-idp." +
      process.env.REACT_APP_COGNITO_REGION +
      ".amazonaws.com/",
    {
      headers: {
        "X-Amz-Target": "AWSCognitoIdentityProviderService.InitiateAuth",
        "Content-Type": "application/x-amz-json-1.1",
      },
      mode: "cors",
      cache: "no-cache",
      method: "POST",
      body: JSON.stringify({
        ClientId: process.env.REACT_APP_COGNITO_APP_CLIENT_ID,
        AuthFlow: "REFRESH_TOKEN_AUTH",
        AuthParameters: {
          REFRESH_TOKEN: currentSession.refreshToken.token,
        },
      }),
    }
  );
  const data = await response.json();
  localStorage.setItem("idToken", data.AuthenticationResult.IdToken);
  if (response.status >= 400) {
    throw new Error(data.errors);
  }
  return data.AuthenticationResult.IdToken;
};

export const getDataFromKpiDash = async (postData, Endpoint) => {
  const response = await fetch(`${API_BASE_URL_DATA}${Endpoint}`,
  {
      method: 'POST',
      headers: {
          'X-Api-Key': API_KEY_PICTREE_DATA,
          'Content-Type': 'application/json',
      },
      body: JSON.stringify(postData)
  });
  const data = await response.json();
  if (response.status >= 400) {
      throw new Error(data.errors);
  }
  return data;
}

export const getDataFromSxi = async (postData, Endpoint, token) => {
  const response = await fetch(`${API_BASE_URL}${Endpoint}`,
  {
      method: 'POST',
      headers: {
          'Authorization': token,
          'Content-Type': 'application/json',
      },
      body: JSON.stringify(postData)
  });
  const data = await response.json();
  if (response.status >= 400) {
      throw new Error(data.errors);
  }
  return data;
}
