import React, { Component } from "react";
import { MuiThemeProvider, createTheme } from "@material-ui/core/styles";
import red from "@material-ui/core/colors/red";
import * as V from "../styles/variables";

class AppProvider extends Component {
  render() {
    const theme = createTheme({
      spacing: 8,
      palette: {
        primary: {
          main: V.primeColour,
        },
        secondary: { main: "#4acac2" },
        error: red,
      },
      typography: {
        useNextVariants: true,
        fontFamily: "Varta,Arial,sans-serif",
        headline: {
          fontSize: "1rem",
        },
        subheading: {
          fontSize: "0.9125rem",
        },
        button: {
          fontWeight: 400,
          textTransform: "initial",
        },
      },
      shape: {
        borderRadius: 1,
      },
      overrides: {
        MuiTypography: {
          root: {
            color: "#000",
          },
          body1: {
            fontSize: "0.8rem",
          },
          headline: {
            fontSize: "1rem",
          },
        },
        MuiToolbar: {
          root: {
            color: "rgba(0, 0, 0, 0.54)",
          },
        },
        MuiButton: {
          root: {
            textTransform: "none",
            borderRadius: 8,
          },
          contained: {
            "&:hover": {
              backgroundColor: "#7f898b",
            },
          },
        },
        MuiInput: {
          underline: {
            "&:before": {
              borderBottom: "1px solid #65C9A5",
            },
            "&:after": {
              borderBottom: `2px solid #65C9A5`,
            },
            "&:hover:not($disabled):not($focused):not($error):before": {
              borderBottom: `2px solid #65C9A5`,
            },
          },
          input: {
            display: "flex",
          },
        },
        MuiTooltip: {
          tooltip: {
            fontSize: "14px",
            backgroundColor: V.iconColor,
            borderRadius: 4,
          },
        },
        MuiFormLabel: {
          root: {
            marginLeft: "5px",
          },
        },
        MuiChip: {
          root: {
            marginRight: "5px",
            marginBottom: "5px",
          },
          labelSmall: {
            paddingTop: 4,
            maxWidth: 250,
          },
        },
        MuiCard: {
          root: {
            borderRadius: 6,
          },
        },
        MuiCardHeader: {
          title: {
            marginTop: 8,
            fontSize: 15,
          },
          subheader: {
            marginTop: 4,
          },
          root: {
            paddingTop: 8,
            paddingLeft: 16,
            paddingBottom: 0,
          },
        },
        MuiCardContent: {
          root: {
            "&:last-child": {
              paddingBottom: 16,
            },
          },
        },
        MuiInputAdornment: {
          root: {
            maxHeight: "none",
          },
        },
        MUIDataTable: {
          paper: {
            minHeight: "480px",
          },
        },
        MuiTableCell: {
          root: {
            padding: "4px",
          },
          body: {
            textAlign: "center",
            padding: 8,
          },
          paddingCheckbox: {
            width: 30,
          },
        },
        MUIDataTableSelectCell: {
          fixedHeaderCommon: {
            backgroundColor: "none",
          },
        },
        MUIDataTableSearch: {
          clearIcon: {
            width: "32px",
            height: "32px",
            marginTop: "16px",
          },
          searchIcon: {
            marginTop: "16px",
          },
        },
        MUIDataTableToolbarSelect: {
          iconButton: {
            width: "40px",
            height: "40px",
          },
        },
        MUIDataTableHeadCell: {
          fixedHeader: {
            fontSize: "14px",
          },
          fixedHeaderYAxis: {
            textAlign: "center",
          },
          toolButton: {
            display: "block",
            textAlign: "center",
          },
          sortAction: {
            justifyContent: "center",
            display: "inline",
          },
        },
        MuiListItemText: {
          root: {
            padding: 0,
          },
        },
        MuiIcon: {
          root: {
            color: V.iconColor,
          },
        },
        MuiAvatar: {
          root: {
            width: 30,
            height: 30,
          },
        },
        MuiFilledInput: {
          underline: {
            "&:before": {
              borderBottom: `1px solid ${V.primeColour}`,
            },
            "&:hover": {
              borderBottom: `1px solid ${V.primeColour}`,
            },
            "&:hover:not($disabled):not($focused):not($error):before": {
              borderBottom: `1px solid ${V.primeColour}`,
            },
          },
        },
        MuiIconButton: {
          root: {
            //padding: 12
          },
        },
        MuiFormControlLabel: {
          root: {
            paddingRight: 32,
          },
        },
        MuiListItemIcon: {
          root: {
            minWidth: "32px",
          },
        },
        MuiSelect: {
          selectMenu: {
            minHeight: "1em",
          },
        },
        MuiDialogTitle: {
          root: {
            paddingLeft: 32,
            paddingBottom: 0,
          },
        },
        MuiAppBar: {
          colorPrimary: {
            backgroundColor: "#f5f5f5",
          },
        },
        MuiAutocomplete: {
          tagSizeSmall: {
            maxWidth: 300,
          },
        },
        MuiOutlinedInput: {
          root: {
            borderRadius: 4,
          },
        },
        MuiPaper: {
          rounded: {
            borderRadius: 8,
          },
        },
      },
    });

    return (
      <MuiThemeProvider theme={theme}>{this.props.children}</MuiThemeProvider>
    );
  }
}

export default AppProvider;
