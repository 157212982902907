import { APP } from "../constants";
import moment from "moment";

const startDate = moment().tz("Asia/Tokyo").subtract(6, 'days').format('YYYY-MM-DD');
const endDate = moment().tz("Asia/Tokyo").format('YYYY-MM-DD');

const initialState = {
  currentPage: "",
  pageTopLoader: false,
  tagSavingLoader: false,
  commentSavingLoader: false,
  jwtoken: null,
  accessKey: null,
  username: null,
  userEmail: null,
  dateRange: [startDate, endDate],
  minMaxDateRange: [null, null],
  periodType: "season",
  filterDrawerState: false,
  selectedSeason: "",
  seasonData: [],
  missionData: [],
  selectedMission: "blank",
  selectedTag: [],
  openTagTip: false,
  assetComment: "",
};

export default function appReducer(state = initialState, action) {
  switch (action.type) {
    case APP.SET_CURRENT_PAGE:
      return {
        ...state,
        currentPage: action.payload,
      };
    case APP.SET_PAGE_TOP_LOADER:
      return {
        ...state,
        pageTopLoader: action.payload,
      };
    case APP.SET_TAG_SAVING_LOADER:
      return {
        ...state,
        tagSavingLoader: action.payload,
      };
    case APP.SET_COMMENT_SAVING_LOADER:
      return {
        ...state,
        commentSavingLoader: action.payload,
      };
    case APP.SET_JWTOKEN:
      return {
        ...state,
        jwtoken: action.payload,
      };
    case APP.SET_ACCESS_KEY:
      return {
        ...state,
        accessKey: action.payload,
      };
    case APP.SET_USERNAME:
      return {
        ...state,
        username: action.payload,
      };
    case APP.SET_USER_EMAIL:
      return {
        ...state,
        userEmail: action.payload,
      };
    case APP.SET_DATE_RANGE:
      return {
          ...state,
          dateRange: action.payload
      };
    case APP.SET_MIN_MAX_DATE_RANGE:
      return {
          ...state,
          minMaxDateRange: action.payload
      };
    case APP.SET_PERIOD_TYPE:
      return {
          ...state,
          periodType: action.payload
      };
    case APP.SET_SELECTED_SEASON:
      return {
          ...state,
          selectedSeason: action.payload
      };
    case APP.SET_FILTER_DRAWER_STATE:
      return {
          ...state,
          filterDrawerState: action.payload
      };
    case APP.SET_SEASON_DATA:
      return {
          ...state,
          seasonData: action.payload
      };
    case APP.SET_MISSION_DATA:
      return {
          ...state,
          missionData: action.payload
      };
    case APP.SET_SELECTED_MISSION:
      return {
          ...state,
          selectedMission: action.payload
      };
    case APP.SET_SELECTED_TAG:
      return {
          ...state,
          selectedTag: action.payload
      };
    case APP.SET_DRAWER_TAG_TIP:
      return {
        ...state,
        openTagTip: action.payload,
      };
    case APP.SET_ASSET_COMMENT:
      return {
        ...state,
        assetComment: action.payload,
      };
    default:
      return state;
  }
}
