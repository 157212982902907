import { APP, HOME, VIEWER } from '../constants';

// App
export const setCurrentPage = (param) => ({
    type: APP.SET_CURRENT_PAGE,
    payload: param
});

export const setJwtoken = (param) => ({
    type: APP.SET_JWTOKEN,
    payload: param
});

export const setPageTopLoader = (param) => ({
    type: APP.SET_PAGE_TOP_LOADER,
    payload: param
});

export const setIdToken = (param) => ({
    type: APP.SET_ID_TOKEN,
    payload: param
});

export const setAccessKey = (param) => ({
    type: APP.SET_ACCESS_KEY,
    payload: param
});

export const setUserName = (param) => ({
    type: APP.SET_USERNAME,
    payload: param
});

export const setUserEmail = (param) => ({
    type: APP.SET_USER_EMAIL,
    payload: param
});

export const setDateRange = (param) => ({
    type: APP.SET_DATE_RANGE,
    payload: param
});

export const setMinMaxDateRange = (param) => ({
    type: APP.SET_MIN_MAX_DATE_RANGE,
    payload: param
});

export const setPeriodType = (param) => ({
    type: APP.SET_PERIOD_TYPE,
    payload: param
});

export const getSeasonData = () => ({
    type: APP.GET_SEASON_DATA,
});

export const setSeasonData = (param) => ({
    type: APP.SET_SEASON_DATA,
    payload: param
});


export const setSelectedSeason = (param) => ({
    type: APP.SET_SELECTED_SEASON,
    payload: param
});

export const setFilterDrawerState = (param) => ({
    type: APP.SET_FILTER_DRAWER_STATE,
    payload: param
});

export const setSelectedTag = (param) => ({
    type: APP.SET_SELECTED_TAG,
    payload: param
});

export const setTagSavingLoader = (param) => ({
    type: APP.SET_TAG_SAVING_LOADER,
    payload: param
});

export const getSavedSelectedTagDataByFileName = (param) => ({
    type: APP.GET_SAVED_SELECTED_TAG_DATA_BY_FILENAME,
    payload: param
});

export const getSavedSelectedTagDataByEmail = () => ({
    type: APP.GET_SAVED_SELECTED_TAG_DATA_BY_EMAIL,
});

export const setSavedSelectedTagData = (param) => ({
    type: APP.SET_SAVED_SELECTED_TAG_DATA,
    payload: param
});

export const setDrawerTagTip = (param) => ({
    type: APP.SET_DRAWER_TAG_TIP,
    payload: param
});

export const setCommentSavingLoader = (param) => ({
    type: APP.SET_COMMENT_SAVING_LOADER,
    payload: param
});

export const setAssetComment = (param) => ({
    type: APP.SET_ASSET_COMMENT,
    payload: param
});

export const setSavedCommentData = (param) => ({
    type: APP.SET_SAVED_COMMENT_DATA,
    payload: param
});

export const getSavedCommentDataByFileName = (param) => ({
    type: APP.GET_SAVED_COMMENT_DATA_BY_FILENAME,
    payload: param
});

// Mission
export const getMissionData = () => ({
    type: APP.GET_MISSION_DATA,
});

export const setMissionData = (param) => ({
    type: APP.SET_MISSION_DATA,
    payload: param
});

export const setSelectedMission = (param) => ({
    type: APP.SET_SELECTED_MISSION,
    payload: param
});


// HOME
export const getHomeTotalStatsData = () => ({
    type: HOME.GET_TOTAL_STATS_DATA,
});

export const setHomeTotalStatsData = (param) => ({
    type: HOME.SET_TOTAL_STATS_DATA,
    payload: param
});

export const getHomeImageData = (param) => ({
    type: HOME.GET_HOME_IMAGE_DATA,
    payload: param
});

export const setHomeImageData = (param) => ({
    type: HOME.SET_HOME_IMAGE_DATA,
    payload: param
});

export const setTotalImageCount =  (param) => ({
    type: HOME.SET_TOTAL_IMAGE_COUNT,
    payload: param
});

export const setCurrentPageNumber =  (param) => ({
    type: HOME.SET_CURRENT_PAGE_NUMBER,
    payload: param
});

export const setFilteredTotal = (param) => ({
    type: HOME.SET_FILTERED_TOTAL,
    payload: param
});

export const setFilter = (param) => ({
    type: HOME.SET_FILTER,
    payload: param
});

// VIEWER
export const getImageDataByFileName = (param) => ({
    type: VIEWER.GET_IMAGE_DATA_BY_FILENAME,
    payload: param
});

export const setImageDataByFileName = (param) => ({
    type: VIEWER.SET_IMAGE_DATA_BY_FILENAME,
    payload: param
});

export const setViewerImageData = (param) => ({
    type: VIEWER.SET_VIEWER_IMAGE_DATA,
    payload: param
});
