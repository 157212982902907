const lightBlue = require('@material-ui/core/colors/lightBlue');
const yellow = require('@material-ui/core/colors/yellow');

// Colors
const primeColour = '#67c8a5';
const infoColor = lightBlue.default[500];
const warningColor = yellow.default[500];
const placeholderColor = "#dcdcdc";
const textColor = 'rgba(0, 0, 0, 0.87)';
const iconColor = '#7f898b';
const mapDarkColor = '#777777';
const mapWithColor = '#b6e59e';
const mapLightColor = '#eeeeee';
const btnLabelColor = '#ffffff';

// Spacing
const drawerWidth = 220;
const notificationCenterWidth = 300;
const MobileBreakpoint = 960;

// fontSize
const sidebarFontSize = 14;

// Chart
const chartLineWidth = 4;
const chartSymbolSize = 8;

export {
    primeColour,
    infoColor,
    warningColor,
    placeholderColor,
    textColor,
    iconColor,
    mapDarkColor,
    mapWithColor,
    mapLightColor,
    btnLabelColor,
    drawerWidth,
    notificationCenterWidth,
    sidebarFontSize,
    chartLineWidth,
    chartSymbolSize,
}