import { all } from 'redux-saga/effects';

import appSaga from './appSaga';
import homeSaga from './homeSaga';
import viewerSaga from './viewerSaga';

export default function* rootSaga() {
    yield all([
        appSaga(), homeSaga(), viewerSaga(),
    ]);
}
