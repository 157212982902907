const FormatNumberHelper = {

    toFixedTrunc: function (x, n) {
        const v = (typeof x === 'string' ? x : x.toString()).split('.');
        if (n <= 0) return v[0];
        let f = v[1] || '';
        if (f.length > n) return `${v[0]}.${f.substr(0,n)}`;
        while (f.length < n) f += '0';
        return `${v[0]}.${f}`
    },

    formatCommaThousand: function(value) {
        if (value === 0) {
            return value;
        }
        if (value){
            return (value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")).split('.')[0];
        }
    },

    formatCommaToNumbers: function(value) {
        if (value === 0) {
            return value;
        }
        return parseInt(value.replace(/,/g, ''))
    },

    numWarHammer: function(value) {
        if (value > 9999) {
            value = value / 1000;
            value = value.toString() + 'K';
        }
        return value;
    },

    roundPercentDisplay: function(value, lang) {
        let roundPercentFixedTwo = (Math.round(value * 100) / 100).toFixed(2);
        roundPercentFixedTwo = this.i18nFormat(roundPercentFixedTwo, lang);
        let percentStr = lang === 'fr' ? ' %' : '%';
        return '(' + roundPercentFixedTwo + percentStr + ')';
    },

    roundSingleDigitPercentDisplay: function(value) {
        let roundPercentFixedTwo = this.toFixedTrunc(value*100,1)
        return roundPercentFixedTwo;
    },

    percentTodecimal: function(value) {
        if (value === null) {
            return 'N/A';
        }
       return parseFloat(value) / 100.0;
    },

    formatDataLift: function(value) {
        if (value === 0) {
            return '0%';
        }
        if (value === null) {
            return 'N/A';
        }
        return (value * 100).toFixed(2) + '%';
    },

    roundDictionaryToPercent: function(data){
        if (data == null){
            return data
        }
        Object.entries(data[0]).forEach(([key, value]) => {
            for (var i in value){
                var tmp = Object.values(value[i])
                value[i].value = this.roundSingleDigitPercentDisplay(tmp[1])+'%'
            }
        });
        return data
    },

    i18nFormat: function(value, lang) {
        let returnValue = value;
        if (lang === 'fr' && typeof value === 'string') {
            if (returnValue.indexOf(",") !== -1) {
                returnValue = returnValue.replace(/,/g, " ");
            }
            if (returnValue.indexOf("%") !== -1) {
                returnValue = returnValue.replace("%", " %");
            }
            if (returnValue.indexOf(".") !== -1) {
                returnValue = returnValue.replace(".", ",");
            }
        }
        return returnValue
    }
}

export default FormatNumberHelper;