import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Auth } from "aws-amplify";
import { Link } from "react-router-dom";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import ResetPasswordStyle from "./ResetPasswordStyles";

export class ResetPassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      code: "",
      username: "",
      password: "",
      confirmPassword: "",
      codeSent: false,
      confirmed: false,
      isConfirming: false,
      isSendingCode: false,
      errorMessage: "",
      disabled: true,
    };

    this.validatorListener = this.validatorListener.bind(this);
  }

  componentDidMount() {
    ValidatorForm.addValidationRule("isPasswordMatch", (value) => {
      if (value !== this.state.password) {
        return false;
      }
      return true;
    });
    ValidatorForm.addValidationRule("checkPasswordLength", (value) => {
      if (value !== "" && value.length < 6) {
        return false;
      }
      return true;
    });
    ValidatorForm.addValidationRule("checkPasswordLowerCase", (value) => {
      if (value !== "" && value.search(/[a-z]/) < 0) {
        return false;
      }
      return true;
    });
    ValidatorForm.addValidationRule("checkPasswordUpperCase", (value) => {
      if (value !== "" && value.search(/[A-Z]/) < 0) {
        return false;
      }
      return true;
    });
    ValidatorForm.addValidationRule("checkPasswordSpecialChar", (value) => {
      if (
        value !== "" &&
        value.search(/[-!@#$%^&*()_+|~=`{}[\]:";'<>?,./]/) < 0
      ) {
        return false;
      }
      return true;
    });
  }

  validateCodeForm() {
    return this.state.username.length > 0;
  }

  validatorListener(result) {
    if (
      this.state.code.length > 0 &&
      this.state.password.length > 6 &&
      this.state.password === this.state.confirmPassword &&
      result
    ) {
      this.setState({ disabled: false });
    } else {
      this.setState({ disabled: true });
    }
  }

  handleChange = (event) => {
    this.setState({
      [event.target.id]: event.target.value,
    });
    this.setState({ errorMessage: "" });
  };

  handleSendCodeClick = async (event) => {
    event.preventDefault();
    this.setState({ isSendingCode: true });
    try {
      await Auth.forgotPassword(this.state.username);
      this.setState({ codeSent: true });
    } catch (e) {
      this.setState({ isSendingCode: false });
      this.setState({ errorMessage: "Email address is invalid." });
    }
  };

  handleConfirmClick = async (event) => {
    event.preventDefault();
    this.setState({ isConfirming: true });
    try {
      await Auth.forgotPasswordSubmit(
        this.state.username,
        this.state.code,
        this.state.password
      );
      this.setState({ confirmed: true });
    } catch (e) {
      this.setState({ isConfirming: false });
    }
  };

  renderRequestCodeForm() {
    const { classes } = this.props;
    return (
      <Card>
        <CardContent>
          <form onSubmit={this.handleSendCodeClick}>
            <div className="text-xs-center pb-xs">
              <Typography variant="title">パスワードをリセットする</Typography>
            </div>
            <div className={classes.formMessage}>
              登録したメールアドレスを入力してください。
            </div>
            <TextField
              id="username"
              label="メールアドレス"
              className={classes.textField}
              fullWidth
              margin="normal"
              value={this.state.username}
              onChange={this.handleChange}
              autoFocus
            />
            <div className={classes.errorMessageDiv}>
              <Typography className={classes.errorMessage}>
                {this.state.errorMessage}
              </Typography>
            </div>
            <Button
              className={classes.submitButton}
              variant="contained"
              fullWidth
              type="submit"
              disabled={!this.validateCodeForm()}
            >
              確認コードを送信する
            </Button>
          </form>
        </CardContent>
      </Card>
    );
  }

  renderConfirmationForm() {
    const { classes } = this.props;
    return (
      <Card>
        <CardContent>
          <ValidatorForm
            ref="form"
            onSubmit={this.handleConfirmClick}
            onError={(errors) => console.log(errors)}
          >
            <div className="text-xs-center pb-xs">
              <img src="/static/images/logo-dark.svg" alt="" />
              <Typography variant="title">新しいパスワードの設定</Typography>
            </div>
            <div className={classes.formMessage}>
              確認コードを受信するためにメールをご確認ください。
            </div>

            <TextValidator
              id="code"
              name="code"
              label="確認コード"
              type="text"
              onChange={this.handleChange}
              fullWidth={true}
              value={this.state.code}
              validators={["required"]}
              errorMessages={["確認コードが必要です"]}
              validatorListener={this.validatorListener}
              className={classes.pwTextField}
              autoFocus
            />

            <TextValidator
              id="password"
              name="password"
              label="新しいパスワード"
              type="password"
              fullWidth={true}
              onChange={this.handleChange}
              value={this.state.password || ""}
              validators={[
                "checkPasswordLength",
                "checkPasswordLowerCase",
                "checkPasswordUpperCase",
                "checkPasswordSpecialChar",
              ]}
              errorMessages={[
                "パスワードは少なくとも6文字である必要があります",
                "パスワードには小文字を含める必要があります",
                "パスワードには大文字を含める必要があります",
                "パスワードには特殊文字を含める必要があります",
              ]}
              validatorListener={this.validatorListener}
              className={classes.pwTextField}
            />

            <TextValidator
              id="confirmPassword"
              name="confirmPassword"
              label="パスワードを確認してください"
              type="password"
              fullWidth={true}
              onChange={this.handleChange}
              value={this.state.confirmPassword || ""}
              validators={["isPasswordMatch"]}
              errorMessages={["パスワードが一致しません"]}
              className={classes.pwTextField}
              validatorListener={this.validatorListener}
            />

            <Button
              className={classes.submitButton}
              variant="contained"
              fullWidth
              type="submit"
              disabled={this.state.disabled}
            >
              Submit
            </Button>
          </ValidatorForm>
        </CardContent>
      </Card>
    );
  }

  renderSuccessMessage() {
    return (
      <Card>
        <CardContent>
          <div className="success">
            <p>Your password has been reset.</p>
            <p>
              Click <Link to="/signin">here</Link> to sign in with your new
              credentials.
            </p>
          </div>
        </CardContent>
      </Card>
    );
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classNames(classes.session, classes.background)}>
        <div className={classes.branding}>
          <Link to="/">
            <img
              src={`/static/images/logo-main.png`}
              alt={"Foot Traffic Attribution"}
            />
          </Link>
        </div>
        <div className={classes.content}>
          <div className={classes.wrapper}>
            <div className="ResetPassword">
              {!this.state.codeSent
                ? this.renderRequestCodeForm()
                : !this.state.confirmed
                ? this.renderConfirmationForm()
                : this.renderSuccessMessage()}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

ResetPassword.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(ResetPasswordStyle)(ResetPassword);
