import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withStyles, makeStyles, createStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import IconButton from "@material-ui/core/IconButton";
import { Typography, Chip } from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';
import CloseIcon from "@material-ui/icons/Close";
import { setPeriodType } from "../../actions";
import { setFilter, getSavedSelectedTagDataByEmail, getHomeImageData, setCurrentPageNumber } from "../../actions";
import TagConfig from "../../config/TagConfig";

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      width: 500,
      padding: 16,
      position: "relative", // Ensure the container is relative
      height: "100vh", // Ensure the container takes the full height
      boxSizing: "border-box", // Include padding in height
    },
    filter: {
      marginTop: theme.spacing(2),
      marginLeft: theme.spacing(2),
      paddingTop: theme.spacing(5),
    },
    filterItem: {
      marginTop: theme.spacing(4),
    },
    filterButtonContainer: {
      position: "absolute",
      bottom: 45,
      left: "50%",
      transform: "translateX(-50%)",
      width: "100%",
      display: "flex",
      justifyContent: "space-around",
    },
    formControl: {
      margin: theme.spacing(1),
      width: "89%",
    },
    dataBtn: {
      minWidth: 200,
      height: theme.spacing(4),
      paddingTop: theme.spacing(1.5),
    },
    tagSelect: {
      marginTop: theme.spacing(1),
      marginLeft: theme.spacing(1),
      fontSize: 16,
      minWidth: 416,
      maxWidth: 416,
      "& .MuiInputLabel-root": {
        fontSize: "16px", // Smaller font size for the label (placeholder)
      },
      "& .MuiInputBase-input": {
        fontSize: "16px", // Customize input text size (if needed)
      }
    },
  })
);

const FilterDrawer = ({
  setRange,
  dateRange,
  periodType,
  setPeriodType,
  toggleDrawer,
  anchor,
  setFilter,
  filterStore,
  getSavedSelectedTagDataByEmail,
  getHomeImageData,
  setCurrentPageNumber,
}) => {
  const classes = useStyles();
  const [sort, setSort] = useState(`${filterStore.type}-${filterStore.order}`);
  const [signNumber, setSignNumber] = useState("");
  const [assetId, setAssetId] = useState("");
  const [tagData, setTagData] = useState([]);
  const [assetType, setAssetType] = useState("");

  useEffect(() => {
    setAssetId(filterStore.assetId);
  }, [filterStore.assetId]);

  useEffect(() => {
    setSignNumber(filterStore.signNumber);
  }, [filterStore.signNumber]);

  useEffect(() => {
    setAssetType(filterStore.assetType);
  }, [filterStore.assetType]);

  useEffect(() => {
    if (periodType === "season") {
      setTagData(TagConfig.seasonTag);
    } else {
      setTagData(TagConfig.missionTagB.concat(TagConfig.missionTagC));
    }
  }, [periodType]);

  const handleSignNumberChange = (event) => {
    const value = event.target.value;
    let copyFilter =  {...filterStore};
    copyFilter.signNumber = value;
    setFilter(copyFilter);
  };

  const handleAssetIdChange = (event) => {
    const value = event.target.value;
    let copyFilter =  {...filterStore};
    copyFilter.assetId = value;
    setFilter(copyFilter);
  };

  const handleAssetTypeChange = (event) => {
    const value = event.target.value;
    let copyFilter =  {...filterStore};
    copyFilter.assetType = value;
    setFilter(copyFilter);
  };

  const handleChange = (event) => {
    const filterValue = event.target.value.split("-");
    const filter = {
      type: filterValue[0],
      order: filterValue[1],
      tag: filterStore.tag,
      assetId: filterStore.assetId,
      signNumber: filterStore.signNumber,
      assetType: filterStore.assetType,
    };
    setSort(event.target.value);
    setFilter(filter);
  };

  const fetchData = () => {
    if (filterStore.tag !== undefined && filterStore.tag.length > 0) {
      getSavedSelectedTagDataByEmail();
    } else {
      getHomeImageData({
        pageNumber: 1,
      });
    }
    setCurrentPageNumber(1);
    toggleDrawer(anchor, false)();
  };

  const handleReset = () => {
    setFilter({
      type: "occurred_datetime",
      order: "desc",
      tag: [],
      assetId: null,
      signNumber: null,
      assetType: "all",
    });
    setAssetId("");
    setSignNumber("");
    setSort("occurred_datetime-desc");
    setAssetType("");
    toggleDrawer(anchor, false)();
    setCurrentPageNumber(1);
    getHomeImageData({
      pageNumber: 1,
    });
  };

  const handleSelectTagChange = (tags) => {
    let copyFilter =  {...filterStore}
    copyFilter.tag = tags;
    setFilter(copyFilter);
  };

  return (
    <div className={classes.container}>
      <IconButton
        className={classes.menuButton}
        color="inherit"
        aria-label="open drawer"
        onClick={toggleDrawer(anchor, false)}
        disableRipple={true}
        disableFocusRipple={true}
      >
        <CloseIcon />
      </IconButton>
      絞り込み条件設定
      <br />
      <Grid
        container
        direction="column"
        justifyContent={"flex-start"}
        className={classes.filter}
      >
        <Grid item>
          <TextField
            id="signNumber"
            label="標識番号"
            variant="outlined"
            style={{ width: "89%", paddingLeft: 6 }}
            value={signNumber}
            onChange={handleSignNumberChange}
          />
        </Grid>
        <Grid item className={classes.filterItem}>
          <TextField
            id="assetId"
            label="設備番号"
            variant="outlined"
            style={{ width: "89%", paddingLeft: 6 }}
            value={assetId}
            onChange={handleAssetIdChange}
          />
        </Grid>
        <Grid item className={classes.filterItem}>
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel id="filter-assetType-label">
              アセットタイプ
            </InputLabel>
            {periodType === "mission" &&
              <Select
                labelId="simple-select-assetType-label"
                id="simple-select-assetType"
                value={assetType}
                onChange={handleAssetTypeChange}
                label="アセットタイプ"
              >
                <MenuItem value="MISSION_ASSET">全てのミッションアセット</MenuItem>
              </Select>
            }
            {periodType === "season" &&
              <Select
                labelId="simple-select-assetType-label"
                id="simple-select-assetType"
                value={assetType}
                onChange={handleAssetTypeChange}
                label="アセットタイプ"
              >
                <MenuItem value="all">全て</MenuItem>
                <MenuItem value="POWER_POLE">電柱</MenuItem>
                <MenuItem value="GRATING">換気口</MenuItem>
                <MenuItem value="MAN_HOLE">マンホール</MenuItem>
                <MenuItem value="HAND_HOLE">ハンドホール</MenuItem>
                <MenuItem value="GROUND_EQUIPMENT-MS">地上設備 (設備コード:MS)</MenuItem>
                <MenuItem value="GROUND_EQUIPMENT-PM">地上設備 (設備コード:PM)</MenuItem>
              </Select>
            }
          </FormControl>
        </Grid>
        <Grid item className={classes.filterItem}>
        {tagData !== null && tagData.length > 0 &&
          <Autocomplete
            multiple
            id="filter-tag-select"
            options={tagData}
            getOptionLabel={(option) => option.value || ""}
            getOptionSelected={(option, value) => option.value.toString() === value.toString()}
            value={filterStore.tag !== undefined && filterStore.tag.length > 0
              ? tagData.filter(tag => filterStore.tag.includes(tag.value.toString()))
              : []
            }
            onChange={(event, newValue) => {
              if (newValue) {
                const selectedValues = newValue.map((tag) => tag.value.toString());
                handleSelectTagChange(selectedValues);
              } else {
                handleSelectTagChange([]);
              }
            }}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  key={index}
                  size="small"
                  label={option.value}
                  {...getTagProps({ index })}
                  style={{ backgroundColor: '#ffed6f', color: 'black', }} // Set Chip color
                />
              ))
            }
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label="結果タグ"
                className={classes.tagSelect}
              />
            )}
            renderOption={(option) => (
              <div style={{ display: 'flex', flexDirection: 'column', width: "100%" }}>
                <Typography variant="body1">
                  {option.tip}
                </Typography>
              </div>
            )}
          />
        }
        </Grid>
        <Grid item className={classes.filterItem}>
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel id="filter-sort-label">
              ソート
            </InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={sort}
              onChange={handleChange}
              label="ソート"
            >
              <MenuItem value="occurred_datetime-desc">
                撮影日時 - 降順
              </MenuItem>
              <MenuItem value="occurred_datetime-asc">
                撮影日時 - 昇順
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <div className={classes.filterButtonContainer}>
          <Button
            className={classes.dataBtn}
            variant="contained"
            color="primary"
            onClick={fetchData}
          >
            絞り込む
          </Button>
          <Button
            className={classes.dataBtn}
            variant="contained"
            color="default"
            onClick={handleReset}
          >
            リセット
          </Button>
        </div>
      </Grid>
    </div>
  );
};

FilterDrawer.prototypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  dateRange: state.app.dateRange,
  periodType: state.app.periodType,
  filterStore: state.home.filter,
});

const mapDispatchToProps = (dispatch) => ({
  setPeriodType: (param) => dispatch(setPeriodType(param)),
  setFilter: (param) => dispatch(setFilter(param)),
  getSavedSelectedTagDataByEmail: () => dispatch(getSavedSelectedTagDataByEmail()),
  getHomeImageData: (param) => dispatch(getHomeImageData(param)),
  setCurrentPageNumber: (param) => dispatch(setCurrentPageNumber(param)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(null, { withTheme: true })(FilterDrawer));
