import { combineReducers } from 'redux';
import appReducer from './appReducer';
import homeReducer from './homeReducer';
import viewerReducer from './viewerReducer';

const rootReducer = combineReducers({
    app: appReducer,
    home: homeReducer,
    viewer: viewerReducer,
});

export default rootReducer;
