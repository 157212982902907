import React, { Component, Fragment } from "react";
import { Auth } from "aws-amplify";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import classNames from "classnames";
import clsx from "clsx";
import { Switch, Route } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import { Workspace, Header, SidebarWrapper } from "../components";
import RoutesDashboard from "../RoutesDashboard";
import DashboardStyles from "./DashboardStyles";

function resizeDispatch() {
  if (typeof Event === "function") {
    window.dispatchEvent(new Event("resize"));
  } else {
    const evt = window.document.createEvent("UIEvents");
    evt.initUIEvent("resize", true, false, window, 0);
    window.dispatchEvent(evt);
  }
}

class Dashboard extends Component {
  state = {
    opened: true,
    username: "",
  };

  handleDrawerToggle = () => {
    this.setState({ opened: !this.state.opened });
    resizeDispatch();
  };

  componentDidMount() {}

  componentWillUnmount() {}

  render() {
    const { classes, filterDrawerState, setFilterDrawerState, currentPage } = this.props;
    const { opened } = this.state;

    const getRoutes = (
      <Switch>
        {RoutesDashboard.items.map((item, index) => (
          <Route
            exact
            path={item.path}
            component={item.component}
            name={item.name}
            key={index}
          />
        ))}
      </Switch>
    );

    return (
      <Fragment>
        <Header
          logoAltText=""
          logo={`/static/images/logo-main.png`}
          toggleDrawer={this.handleDrawerToggle}
          isAuthenticated={this.props.isAuthenticated}
          handleLogout={this.props.handleLogout}
          currentLocation={this.props.location.pathname}
        />
        <div className={classNames(classes.panel, "theme-dark")}>
          {currentPage === "/home" &&
            <SidebarWrapper
              routes={RoutesDashboard.items}
              opened={opened}
              toggleDrawer={this.handleDrawerToggle}
              handleLogout={this.props.handleLogout}
            />
          }
          <div
            className={clsx(classes.content, {
              [classes.contentShift]: filterDrawerState,
            })}
          >
            <Workspace
              opened={opened}
              currentLocation={this.props.location.pathname}
            >
              {getRoutes}
            </Workspace>
          </div>
        </div>
      </Fragment>
    );
  }
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  currentPage: state.app.currentPage,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(DashboardStyles, { withTheme: true })(Dashboard));
