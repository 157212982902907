const tagCategory = {
  "A": "ベース運用",
  "B": "ミッション（電柱接触）",
  "C": "ミッション（仮復旧）"
};

const seasonTag = [
  {
    "value": "A0",
    "label": "A0",
    "tip": "A0: なし＝A1-1～A2-3の目的以外で閲覧した結果、特に不良がなかったもの）"
  },
  {
    "value": "A1-1",
    "label": "A1-1",
    "tip": "A1-1: お客様要請＝出向回避（計画作業振替、設計依頼を含む）"
  },
  {
    "value": "A1-2",
    "label": "A1-2",
    "tip": "A1-2: お客様要請＝緊急性を確認したため、現場出向"
  },
  {
    "value": "A1-3",
    "label": "A1-3",
    "tip": "A1-3: お客様要請＝写真では正確に判定できないため、現場出向"
  },
  {
    "value": "A2-1",
    "label": "A2-1",
    "tip": "A2-1: 予防保全＝机上管理（計画作業振替、設計依頼を含む）"
  },
  {
    "value": "A2-2",
    "label": "A2-2",
    "tip": "A2-2: 予防保全＝緊急性を確認したため、現場出向"
  },
  {
    "value": "A2-3",
    "label": "A2-3",
    "tip": "A2-3: 予防保全＝写真では正確に判定できないため、現場出向"
  },
];

const missionTagB =  [
  {
    "value": "B1-1",
    "label": "B1-1",
    "tip": "B1-1: 電柱接触痕から、安全性を確認したため、出向回避"
  },
  {
    "value": "B1-2",
    "label": "B1-2",
    "tip": "B1-2: 電柱接触痕から、緊急性を確認したため、現場出向"
  },
  {
    "value": "B1-3",
    "label": "B1-3",
    "tip": "B1-3: 写真では正確に判定できないため、現場出向"
  },
];

const missionTagC =  [
  {
    "value": "C1-1",
    "label": "C1-1",
    "tip": "C1-1: 仮復旧箇所に段差が無いことを確認したため、出向回避"
  },
  {
    "value": "C1-2",
    "label": "C1-2",
    "tip": "C1-2: 仮復旧箇所に段差が有ることを確認したため、現場出向"
  },
  {
    "value": "C1-3",
    "label": "C1-3",
    "tip": "C1-3: 写真では正確に判定できないため、現場出向"
  },
];

export default {
  tagCategory,
  seasonTag,
  missionTagB,
  missionTagC,
}
